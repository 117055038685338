/**
 * Type: ページ
 * What: ヒストリーゾーンTOP
 */
import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import SimpleSwiper from '../../components/Slider.fade';
import Image from '../../util/Image';
import PostListComponent from '../../components/PostList/PostList.obm';

import useMediaQuery from '../../util/useMediaQuery';
import { Button } from '../../components/Btn';
// import NavObm from '../../components/NavObm';

const mq = useMediaQuery;

const pageTitle = '王貞治ベースボールミュージアム 89パーク・スタジオ';
const pageDescription =
  'E・ZO FUKUOKAの4階、王貞治の魅力を伝え、体を動かすことを楽しむ、 未来志向のスポーツミュージアム。';
const pageSlug = 'obm';
const pageLogo = `logo-${pageSlug}.png`;

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidObm
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: `main-${pageSlug}08.jpg`,
  },
  {
    filename: 'main-obm.jpg',
  },
  {
    filename: `main-${pageSlug}02.jpg`,
  },
  {
    filename: `main-${pageSlug}03.jpg`,
  },
  {
    filename: `main-${pageSlug}04.jpg`,
  },
  {
    filename: `main-${pageSlug}05.jpg`,
  },
  {
    filename: `main-${pageSlug}07.jpg`,
  },
];

// Data Props Template
const Template = ({ data }) => {
  // const scrollToTarget = (elem, offset) => {
  //   const $offset = offset || -110;
  //   scroller.scrollTo(elem, {
  //     duration: 1200,
  //     delay: 0,
  //     offset: $offset,
  //     smooth: 'easeOutQuint',
  //   });
  // };
  const url = data.site.siteMetadata;
  return (
    <Layout pageSlug={pageSlug} bg="bg-4">
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent
        hierarchy={2}
        bread2url="obm/"
        bread2title={pageTitle}
      />

      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap bg-4">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-4">
                  <span>4</span>
                  <span>F</span>
                </div>
                <h3 className="ttl">
                  <Image src={pageLogo} alt={pageTitle} />
                </h3>
              </div>
              {url.ticketReady === '1' ? (
                <div className="btn-wrap mb-0">
                  <Button
                    href={`${url.ticket}#/order?lang=ja&id=${url.uuidObm}`}
                    target="_blank"
                    className="btn-buy"
                    rel="noopener noreferrer"
                  >
                    チケットを探す
                  </Button>
                </div>
              ) : null}
              {/* <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>
                    新型コロナウイルス感染防止の対策について
                  </span>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
        {/*
      <NavObm />
      */}
      </section>

      <section className="section sc-museum bg-12" id="museum-desc">
        <div className="container">
          <div className="inner">
            <h3 className="headline">
              <span>施設紹介</span>
            </h3>
            <div className="sc-columns" id="sisetusyoukai">
              {/* <style>
                {`
                #sisetusyoukai {
                justify-content: center
                }
                .sc-columns:after {
                    content: none;
                }
                `}
              </style> */}
              <div className="col col-6 floorbc4">
                <Link
                  to="/obm/museum/"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image filename="main-history.jpg" />
                      </figure>
                    </div>
                    <div className="col-content">
                      <h3 className="ttl c-4 has-text-align-center">
                        ヒストリーゾーン
                      </h3>
                      {mq('sp') ? (
                        <div className="btn-group">
                          <p className="btn-def no-radius full-left">
                            <span>詳細を見る</span>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col col-6 floorbc4">
                <Link
                  to="/obm/89park/"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image filename="main-89-park.jpg" />
                      </figure>
                    </div>
                    <div className="col-content">
                      <h3 className="ttl c-4 has-text-align-center">
                        89パーク
                      </h3>
                      {mq('sp') ? (
                        <div className="btn-group">
                          <p className="btn-def no-radius full-left">
                            <span>詳細を見る</span>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col col-6 floorbc4">
                <Link
                  to="/obm/studio-harry/"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <div className="col-inner">
                    <div className="col-image">
                      <figure className="image">
                        <Image filename="main-studio-harry.webp" />
                      </figure>
                    </div>
                    <div className="col-content">
                      <h3 className="ttl c-4 has-text-align-center">
                        スタジオ・ハリー
                      </h3>
                      {mq('sp') ? (
                        <div className="btn-group">
                          <p className="btn-def no-radius full-left">
                            <span>詳細を見る</span>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="youtube-wrap mt-6 mb-3 cn-hide">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Kmn7Mnh6kiY?si=nvp3jugkKCOC4jjf"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </section>

      <PostListComponent />

      <section className="section sc-museum bg-12" id="museum">
        <div className="container">
          <div
            className="inner-slim bg-grey"
            // data-sal="slide-up" : 監視対象要素の大きさがビューポートを越した場合スライドアップしない不具合があると思われるためトル。
            data-sal-easing="ease-out-expo"
          >
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <p>
                        大人（16歳以上）: 1800円
                        <br />
                        小人（7歳～15歳）: 900円
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="list-mark">
                ※6歳以下は無料、保護者はチケットの購入が必要です。
              </p>
              <p className="list-mark fwb attention">
                ※お子様(12歳以下)のご入場には、保護者の同伴が必要です。保護者1名に対しお子様3名までご入場いただけます。保護者もチケット購入が必要です。
              </p>
            </div>

            <div className="table-base park-ticket">
              <table>
                <tbody>
                  <tr>
                    <th>89パークチケット</th>
                    <td>
                      <p className="fwb">16歳以上</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,200円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>1,000円</b>
                        </dd>
                      </dl>
                      <p className="fwb mt30">15歳以下</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>600円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>500円</b>
                        </dd>
                      </dl>
                      <p className="mt30">
                        ※ヒストリーゾーンへはご入場いただけません
                      </p>
                      <p>
                        各日程のレギュラー・ピークは空席情報カレンダーでご確認ください。
                      </p>
                      <p>
                        <a href="/ticket/" className="tx-link">
                          空席情報カレンダーはこちら
                        </a>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="list-mark">
                ※6歳以下は無料、保護者はチケットの購入が必要です。
              </p>
              <p className="list-mark mb-5 attention fwb">
                ※お子様(12歳以下)のご入場には、保護者の同伴が必要です。保護者1名に対しお子様3名までご入場いただけます。保護者もチケット購入が必要です。
              </p>
              <p className="text is-12px">
                グッズ販売は免税手続きの対象です。詳細は
                <a href="/tax-free/" className="tx-link">
                  こちら
                </a>
              </p>
            </div>

            <h4 className="title is-5">注意事項</h4>

            <article>
              <ul className="list-base">
                <li>
                  館内状況により、ご入場をお待ちいただく場合がございます。
                </li>
                <li>
                  当施設内での怪我、損失、損害、トラブルなどにつきまして、弊社の責めに帰すべき事由がある場合を除き、弊社は一切の責任を負いません。
                </li>
                <li>
                  故障、メンテナンスなどの理由により予告なく一部機器の稼働を中止する場合がございます。
                </li>
                <li>泥酔者の入場はお断りさせていただきます。</li>
                <li>安全上、バットの持ち込みはお断りさせていただきます。</li>
                <li>
                  スタッフの指示に従っていただけない場合は、入場をお断りすることがございます。
                </li>
              </ul>
            </article>
          </div>
          <div className="place-wrap mt50">
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://info.yomiuri.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-1.png" alt="株式会社読売新聞西部本社" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.agekke.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-2.png" alt="株式会社エイジェック" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.fukuokatrans.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-3.png" alt="福岡トランス株式会社" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://openhouse-group.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="bnr-obm-4.png"
                    alt="株式会社オープンハウスグループ"
                  />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.kyushu-hitachi-systems.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-5.png" alt="株式会社九州日立システムズ" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://jp.toto.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-6.png" alt="ＴＯＴＯ株式会社" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://asahifm.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="bnr-obm-7.png"
                    alt="株式会社アサヒファシリティズ"
                  />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.sanrio.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-8.png" alt="株式会社サンリオ" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.ainj.co.jp/corporate/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="bnr-obm-9.png"
                    alt="株式会社アインホールディングス"
                  />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.takenaka.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-10.png" alt="株式会社竹中工務店" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <Image src="bnr-obm-11.jpg" alt="西鉄旅行" />
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.hiramatsu-go-law.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-12.jpg" alt="平松法律事務所" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.aeon-kyushu.info/sales_promotion/index"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-13.jpg" alt="イオン" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.ctc-g.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-14.jpg" alt="CTC" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://hitotohito-job.jp/list/fukuoka/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-15.jpg" alt="ヒトトヒト" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <Image src="bnr-obm-16.jpg" alt="ソニーマーケティング" />
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <Image src="bnr-obm-17.jpg" alt="ニシイ" />
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.goo-net.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-18.jpg" alt="グーネット" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <a
                  href="https://www.sankyu.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="bnr-obm-19.jpg" alt="山九" />
                </a>
              </div>
            </div>
            <div className="spot-col">
              <div
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                className="spot-logo"
              >
                <Image src="bnr-obm-20.jpg" alt="ファミリーマート" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Template;
